import React from 'react';
import '../App.css';
import HeroSection from '../components/HeroSection';
import ButtonNew from '../components/ButtonNew';

let theme = "gray"

function Home(props) {
  props.changeCurrentPage("")
  console.log(props.language);
  window.scrollTo(0, 0) //When pages loads is will scroll up
  return (
    <>
      <HeroSection translate={props.translate} />
  <div className='infoTxt'>Pris för fysisk besök
    <br/> hos specialist i Endokrinologi är 800 SEK.
    <br/> Då ingår nödvändiga prov och 
    <br/> ultraljud undersökning av sköldkörteln,
    <br/>Övriga önskvärda laboratorieprov debiteras enligt centrallaboratoriets prislista.
  </div>    
  <div></div>

  <div style={{backgroundColor:"black",padding:"22px 0px",color:"white", textAlign:"center",fontSize:"22px"}}>LÄS MER</div>
  <div className="menuHormone">
    <div>
    <ButtonNew 
      goTo= {`/${props.language}/hormones/skoldskorteln`} 
      title= {props.translate("SKÖLDSKÖRTELN","THYROID",)}
      theme= "outline-white"
      />
      <ButtonNew 
        goTo= {`/${props.language}/hormones/hypofysen`} 
        title= {props.translate(`HYPOFYSEN`,`PITUITARY`)}
        theme= "outline-white"
      />
    </div>
    <div>
    <ButtonNew 
      goTo= {`/${props.language}/hormones/binjurarna`} 
      title= {props.translate(`BINJURARNA`,`ADRENALS`)}
      theme= "outline-white"
      />
      <ButtonNew 
        goTo= {`/${props.language}/hormones/biskoldkortlarna`} 
        title= {props.translate(`BISKÖLDKÖRTLARNA`,`PARATHYROIDS`)}
        theme= "outline-white"
      />
    </div>
    <div>
    <ButtonNew 
      goTo= {`/${props.language}/hormones/overvikt`} 
      title= {props.translate(`ÖVERVIKT`,`OBESITY`)}
      theme= "outline-white"
      />
      <ButtonNew 
        goTo= {`/${props.language}/hormones/diabetes`} 
        title= {props.translate(`DIABETES`,`DIABETES`)}
        theme= "outline-white"
      />
    </div>
    <div>
    <ButtonNew 
      goTo= {`/${props.language}/hormones/testiklarna`} 
      title= {props.translate(`TESTIKLARNA`,`TESTICLES`)}
      theme= "outline-white"
      />
      <ButtonNew 
        goTo= {`/${props.language}/hormones/aggstockarna`} 
        title= {props.translate(`ÄGGSTOCKARNA`,`OVARIES`)}
        theme= "outline-white"
      />
    </div>
    <div>
    <ButtonNew 
      goTo= {`/${props.language}/hormones/benskorhet`} 
      title= {props.translate(`BENSKÖRHET`,`OSTEOPOROSIS`)}
      theme= "outline-white"
      />
      <ButtonNew 
        goTo= {`/${props.language}/hormones/langdtillvaxt`} 
        title= {props.translate(`LÄNGDTILLVÄXT`,`BODY STATURE`)}
        theme= "outline-white"
      />
    </div>
  </div>
    </>
  );
}

export default Home;
