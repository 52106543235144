import React,{useState,useEffect} from 'react';
import '../App.css';
// import { Button } from './Button';
import './HeroSection.css';
import ButtonNew from './ButtonNew';

function HeroSection(props) {
  const [dimension, updateDimention] = useState();
  
  useEffect(() => {
    window.addEventListener("resize", () => {
        updateDimention({ 
            ...dimension, 
            width: window.innerWidth, 
            height: window.innerHeight 
        });
        console.log(dimension);
    })
},[window.innerWidth]);
console.log(window.innerWidth);
  return (
    <div className='hero-container'>
      
      <h1>{window.innerWidth < 780 ? props.translate("VI ÄR LÄKARE, SPECIALIST","WE ARE DOCTORS, SPEACIALIST"):props.translate("VI ÄR LÄKARE, SPECIALIST-","WE ARE DOCTORS, SPEACIALIST")}</h1>
      <br/>
      <br/>
      <br/>
      <br/>
      <h1>{props.translate("LEGITIMERADE I ENDOKRINOLOGI","LEGITIMATED IN ENDOCRINOLOGY")}</h1>
      <h2 style={{color:"white", fontSize:"30px", textAlign:'center'}}>{
      props.translate("<br/>BASERAD PÅ <br/>VETENSKAPLIG EVIDENS","BASED ON SCIENTIFIC EVIDENCE")}</h2>
    </div>
  );
}

export default HeroSection;
