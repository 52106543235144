import React from 'react'

function PageNotFound() {
    return (
        <div>
            404 page not found 
        </div>
    )
}

export default PageNotFound
