import React from 'react';
import '../App.css';
import Cards from '../components/Cards';
import Footer from '../components/Footer';
const AddressMap=()=>{
  return (
      <div className="google-map-code">
        <iframe title="google maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2034.4357128197723!2d18.07378351636368!3d59.342374381663355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d43c2969769%3A0x3147e73d05479d4!2s%C3%96stermalmsgatan%2047%2C%20114%2026%20Stockholm!5e0!3m2!1sen!2sse!4v1636542990461!5m2!1sen!2sse" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    
      </div>
  );
}
function BookLocal(props) {
  props.changeCurrentPage(props.currentPage)
  return (
    <>
      <div style={{
        color: 'white',
        backgroundColor: 'rgb(38 38 38)',
        padding: "30px",
        //backgroundColor:'#AED6F1'
        }}>
          {props.translate(`
          <h1>FYSISKT BESÖK</h1>
          <br/>
          <h1>Besökspris 800:- vid varje besök hos specialist i Endokrinologi </h1>
          <br/>
          <h1>Då ingår nödvändiga prov och ultraljud undersökning av sköldkörteln,</h1>
          <br/>
          <h1>Övriga önskvärda laboratorieprov debiteras enligt centrallaboratoriets prislista.</h1>
          <br/>
          <br/>
          Boka själv din tid hos specialistläkare i endokrinologi såhär:
          <br/>
          1) Scrolla ned till önskad besöksdag i kalendern nedanför.
          <br/>
          2) Klicka därefter på ledigt tidspunkt under valt besöksdag.
          <br/>
          3) Fyll i informationsfälten nedanför.
          <br/>
          4) Klicka ”boka”.
          <br/>
          Bekräftelse av din bokning kommer omedelbart på din e-mail.
          Vid tveksamhet, ring istället receptionen som då utför bokningen åt dig: 08 10 15 00 under öppettid.
          `,
          `<h1>PHYSICAL APOINTMENT</h1>
          <br/>
          <h1>Visiting price SEK 800 for each visit to a specialist in Endocrinology</h1>
          <br/>
          <h1>This includes the necessary tests and ultrasound examination of the thyroid gland</h1>
          <br/>
          <h1>Other desirable laboratory samples are charged according to the central laboratory's price list.</h1>
          <br/>
          <br/>
          Book your appointment with a specialist in endocrinology as follows:
          <br/>
          1) Scroll down to the desired visiting day in the calendar below.
          <br/>
          2) Then click on free time during the selected day of visit.
          <br/>
          3) Fill in the information fields below.
          <br/>
          4) Click ”boka”.
          <br/>
          Confirmation of your booking will be sent immediately to your e-mail.
          If in doubt, call the reception who will then make the booking for you: 08 10 15 00 during opening hours.`)}
          </div>
      
      <object width="100%" height="800" type="text/html" data="https://patient.nu/portal/public/calendar/cb7e8e08-fefd-11ea-bde6-67804bf5ecc1">Bokning</object>
      <Cards translate={props.translate}/>
      <div style={{marginTop: "-100px", marginBottom: "50px"}}>{props.translate(`Boka (24/7) en tid för fysiskt besök hos legitimerad specialistläkare i Endokrinologi som passar dig.
Boka lätt på menyraden ovan, eller på telefon till receptionen: 08 10 15 00.
Vi håller väntetiden vid nybesök under en vecka.
<br/>Parkering finns i parkeringsgarage tvärs över gatan.<br/>
Kliniken är tillgänglig med tunnelbanans Röda Linjen från Stockholms Centralstation till närmaste station som är: ”Stadion”. Därifrån tar du utgången mot Karlavägen. Följ Karlavägen åt höger direkt från utgången till första korsningen, som är Sturegatan. Sväng höger på Sturegatan. Följ Sturegatan till första korsningen som är Östermalmsgatan. Sväng vänster på Östermalmsgatan. Gångavstånd från stationen är mindre än 10 minuter.</div>`
,
`Book easily on the menu bar above, or by phone at the reception: 08 10 15 00.
We keep the waiting time for new visits for a week.
<br/> Parking is available in the parking garage across the street. <br/>
The clinic is accessible by metro's Red Line from Stockholm Central Station to the nearest station which is: "Stadium". From there, take the exit towards Karlavägen. Follow Karlavägen to the right directly from the exit to the first intersection, which is Sturegatan. Turn right onto Sturegatan. Follow Sturegatan to the first intersection which is Östermalmsgatan. Turn left onto Östermalmsgatan. Walking distance from the station is less than 10 minutes. </div>`)}</div>
        {AddressMap()}
        

    </>
  );
}

export default BookLocal;
