
import React, { useState, useEffect} from 'react'
import './Hormones.css'
import Article from '../components/Article'
import MiniHeroSection from '../components/MiniHeroSection'
import axios from 'axios';
import Title from '../components/Title'

function Hormones(props) {
  props.changeCurrentPage(props.currentPage+"/"+props.genre)
  
    const [data, setData] = useState([]);
 
    useEffect(async () => {
        const result = await axios(
          `/json/${props.language}/hormones/${props.genre}.json`, //'/skoldskortel.json'
          
        );
     
        setData(result.data);
      }, []);

    window.scrollTo(0, 0) //When pages loads is will scroll up
      
      
    return (
        <> 
        <MiniHeroSection 
            sImage={`/${props.genre}.jpg`}
            sImageAlt={props.genre}/>
        {data.map(item => (
            <div className="hormones_wrapper">
              
               {item.superTitle ? <Title superTitle={item.superTitle}/> : null}  
               {item.bigTitle ? <Title bigTitle={item.bigTitle}/> : null} 
                <Article
                    class={"single_article_window"}
                    title ={item.title}
                    text = {item.text}/>
             {item.articles ? 
                <div className="article_wrapper">
                {item.articles.map(items => (
                <Article
                    class={"multiple_article_window"}
                    title ={items.title}
                    text = {items.text}/>
                  ))}
                </div>
                :null}
            </div>
      ))}
        </>
    )
}

export default Hormones
