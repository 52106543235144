import React,{useEffect,useState} from 'react'
import TypeField from '../components/TypeField'
import MiniHeroSection from '../components/MiniHeroSection'
import ButtonNew from '../components/ButtonNew'
import Title from '../components/Title'
import './OnlineConsulting.css'
import imgBg from '../assets/images/consulting.jpg'
import axios from 'axios'
import Paypal from '../components/paypal'

function OnlineConsulting(props) {
    props.changeCurrentPage(props.currentPage)
    const [error,setError]=useState(false)
    const [showPaymentButton,setShowPaymentButton]=useState(true)
    const [showPaypalButtons,setShowPaypalButtons]=useState(false)
    const [purchased,setPurchased] = useState(false)//purchased
    const [inputValue, setInputValue] = useState({})
    const [showPayment, setShowPayment] = useState("none")
    const [sentStatusMessage, setSentStatusMessage] = useState("")
    const [sentNotification,setSentNotification] = useState("onlineConsluting_verification")
    
    let postThis=(setError)=>{
       
        axios.post('/sendConsultation.php', inputValue)
        
            .then(response => { if (response.data === "sent") {
             
            }else{
                setError(true);
            }                                            
            })
            .catch((err) => {
                let message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
                setError(true);
             
              });

    }
    
    useEffect(() => {
        
        // POST request using axios inside useEffect React hook
        if (purchased) { //purchased
                setSentStatusMessage(props.translate(`Din konsultation har nu betalats och skickats vi återkommer inom kort`,`Your consultation has now been sent vi will be contacting you in short`))
                setShowPaypalButtons(false)
            }
        if (error) { //purchased
            setSentNotification("onlineConsluting_error")
            setSentStatusMessage(props.translate(`Din Konsultation har inte skickats ett fel har inträffat Vänligen mejela oss på info@endocons.com eller ring till 08 10 15 00`,`
            Your Consultation has not been sent an error has occurred Please email us at info@endocons.com or call 08 10 15 00`));
            setShowPayment("none");
        }
            
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [purchased,error]);

    
    //window.scrollTo(0, 0) //When pages loads is will scroll up
    return (
        <>
            <MiniHeroSection 
                bImage={imgBg}/>
            <Title superTitle="ONLINE KONSULTATION" />
             <div className="onlineConsulting_title_wrapper">
            <div className="onlineConsulting_title">{props.translate(`Online konsultation i endokrinologi är avsett som orientering och professionel vägledning av specialistläkare legitimerad i Endokrinologi för dig som patient, men ersättar inte ett patientbesök på specialistmottagning.</div>
            <div className="onlineConsulting_title">Priset för online konsult är 400 SEK per kontakttilfälle. Beloppet återbetalas vid första besök hos specialistläkare på mottagningen.
Online konsult avser endast ditt personliga fall – inte konsultation om annan person eller teoretiska fall.`
, `Online Consultation in Endocrinology is offered as an orientation and professional guidance by a licensed specialist in medical Endocrinology for you personally as a patient, but it is not intended to replace a visit to a specialty clinic in Endocrinology.

The price for an Online consultation is 400 SEK per visit.

Online Consultation is for your own case only and is not intended for the medical case of another person or for theoretical  medical cases`)}</div>
</div>
            <div className="onlineConsluting_bigTitle">{props.translate(`Börja din Online Konsultation med att svara kortfattet på de sex punkterna i fältet härunder:`,`Start your Online Consultation by briefly answering the six questions in the field below:`)}</div>            
            <form className="onlineConsulting_contactForm">
            <TypeField 
                title={props.translate(`Dina 5 viktigaste symptom. Ange vänligen antal år eller månader du har haft symptomen.`,`Your five most important symptoms. Please state how many years or months you have had each symptom:`)}
                name={props.translate(``,``)}symptom
                textArea={true}
                onChange={event => setInputValue({...inputValue, "symptom": event.target.value})}
                />
            <TypeField 
                title={props.translate(`När har du senast undersökts av läkare för dina besvär ange år och månad?`,`When did you last consult a doctor about your symptoms? ( Month: Year: )`)}
                name="lastdoctorvisit"
                onChange={event => setInputValue({...inputValue, "lastdoctorvisit": event.target.value})}

                />
            <TypeField 
                title={props.translate(`Vilken medicin får du för dina besvär?`,`Which medicine are you taking for your symptoms? ( Name: Dose: )`)}
                name="medicin"
                textArea={true}
                onChange={event => setInputValue({...inputValue, "medicin": event.target.value})}

            />
            <TypeField 
                title={props.translate(`Ditt Namn`,`Your Name`)}
                name="name"
                onChange={event => setInputValue({...inputValue, "name": event.target.value})}

            />
             <TypeField 
                title={props.translate(`Ålder(utan personnummer)`,`Age? (no personal-number)`)}
                name="age"
                onChange={event => setInputValue({...inputValue, "age": event.target.value})}

            />
            <TypeField 
                title={props.translate(`Din E-post`,`Your Email`)}
                name="email"
                onChange={event => setInputValue({...inputValue, "email": event.target.value})}
            />
            <TypeField 
                title={props.translate(`Ditt telefonnummer`,`Your phone number`)}
                name="phone"
                onChange={event => setInputValue({...inputValue, "phone": event.target.value})}
            />
            <TypeField 
                title={props.translate(`Vad är din fråga ?`,`What is your question ?`)}
                name="question"
                textArea={true}
                onChange={event => setInputValue({...inputValue, "question": event.target.value})}
            />
            {(purchased || error) ? <div className={sentNotification}>
            {sentStatusMessage}</div>:null}
            {showPaymentButton ? 
                <ButtonNew
                    goTo={''}
                    onClick={()=>{postThis(setError);setShowPayment("block");setShowPaymentButton(false);setShowPaypalButtons(true)}}
                    theme="black"
                    title={props.translate("TILL BETALNING","PAY")}
                />
            :null}
            <div style={{display:showPayment}}>
            <div style={{width:"400px", textAlign:"center",marginBottom:"20px", fontSize:"20px"}}>{purchased ? "Tack, ditt köp gick igenom":"Betala med kreditkort eller paypal"}</div>
            {showPaypalButtons ? <Paypal setPurchased={setPurchased}/>:null}
            </div>
            </form> 
          
    
            
     
            
            <div className="info">{props.translate(`Dina uppgifter skickas per e-mail direkt och förtroligt till vår specialistläkare och besvaras inom 24 timmar på vardager, når du klickar på ”betala”.
Svaret består av en inledande specialistbedömning eller -rådgivning och kan om nödvändigt öppna för ytterligare e-mail konsultation om det anses kunna hjälpa dig.
Dina data lämnas inte ut till någon tredieman (ej heller till offentliga myndigheter) utan ditt skriftliga medgivande (eller domstolsbeslut). Dina data införs i din patientjournal och handläggs enligt svenska patientdatalagen(2008:355), socialstyrelsens regler för patientsekretes och EU’s GDPR (General Data Privacy Register, 2018)
Undersokning, provtagning, behandling, receptutskrivning, intyg och remiss kräver personligt besok pa mottagningen`,`Your patient data above will be transmitted by e-mail directly and confidentially to our Specialist in Endocrinology.

An answer will be returned to your e-mal address within 24 hours on working days when you click “transfer”.

The answer will constitute an initial specialist opinion or advice and may eventually open for a continued contact if judged beneficial for you.

Your patient data will not be made available to any third party (not even to authorities) without your written consent or a court order. Your data will be kept at the clinic in your personal patient file. Your data is handled according the Swedish Government Directive for Patient Data (2008), The Swedish Medical Authority Directive for Patient Privacy, and the EU GDPR (General Data Privacy Registry) 2018. Your data will not be used for research purposes.

Physical exam, blood testing, treatment, prescription of medications, medical certificates or referrals require your scheduled visit to the clinic.`)}</div>

        </>
    )
}

export default OnlineConsulting
