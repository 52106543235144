import React,{useState,useEffect} from 'react';
import '../App.css';
import CardItem from '../components/CardItem';
import personal4 from '../assets/images/personal4.jpg'
import axios from 'axios';
import Article from '../components/Article';
import Title from '../components/Title';

function AboutUs(props) {
  window.scrollTo(0, 0) //When pages loads is will scroll up

  const [data, setData] = useState([]);
 console.log(props.language);
  useEffect(async () => {
      const result = await axios(
        `/json/${props.language}/pages/aboutUs.json`, //'/skoldskortel.json'
      );
      setData(result.data);
    }, []);

  props.changeCurrentPage(props.currentPage)
  return (
    <>
    <div style={{margin:"20px 0px", display:'flex',flexDirection: 'column', alignItems:'center'}}>
    <img src={personal4} style={{width:"40%"}}/>
    <div style={{fontSize:"22px"}}>Flemming Wollesen</div>
    <div>leg läk, F.A.C.P.</div>
    </div>
    {
      data.map(item =>(
     <>
        {item.title ? <div style={{textAlign:"center", fontSize:"40px", color:"grey"}}>{item.title}</div> : null} 
          <div className="article_wrapper">
            {item.articles.map(items =>(
              <Article
              class={"static_multiple_article_window"}
              title ={items.title}
              text = {items.text}/>
              
            ))}
          
          </div>
      </>
      ))
    }
    </>
  );
}

export default AboutUs;
