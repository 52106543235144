import React from 'react';
import './Article.css'
function Article(props) {
    return (
      <>
        <div className={props.class}>
            <div className="article_topTitle" dangerouslySetInnerHTML={{__html: props.title}} />
            {/* <div className="article_topTitle">{props.title}</div> */}
            <div className="article_text" dangerouslySetInnerHTML={{__html: props.text}} />
            {/* <div className="article_text">{props.text}</div> */}
        </div>
      </>
    );
  }
  
  export default Article;
 