import React,{useEffect,useState, useRef} from 'react'

function Paypal(props) {
    const paypal = useRef()
    useEffect(()=>{
        window.paypal.Buttons({
            createOrder:(data,actions,err)=>{
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units:[
                        {
                            description: "Online consult",
                            amount:{
                                currency_code: "SEK",
                                value: "400"
                            }
                        }
                    ]
                })
            },
            onApprove: async(data,actions)=>{
                const order = await actions.order.capture();
                console.log("Betalningen gick igenom");
                props.setPurchased(true)
            },
            onError: (err)=>{
                console.log("error: "+err);
            }
        }).render(paypal.current)
    },[])
    return (
        <>

          <div ref={paypal}></div>  
        </>
    )
}

export default Paypal
