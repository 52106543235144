import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';



function Navbar(props) {

console.log("navbar language: " + props.language);
const languageTask = (lang) => {
  let change = (lang === "sv") ? "en" : "sv"
 return(change)
  
}

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  

  const menuBtn =(title, goTo,icon)=>{
    return(
    <li className='nav-item'>
              <Link
                to={goTo}
                className='nav-links '
                onClick={closeMobileMenu}
              >
              {icon ? <img onClick={()=> props.changeLanguage(languageTask(props.language))} alt="countryFlag" src={`/${props.language}Flag.jpg`} width="40px"></img>:title}
              </Link>
              
            </li>
            )
  }

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
           <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            {menuBtn(props.translate("HUVUDSIDAN","MAINSITE"), `/${props.language}/`)}
            {menuBtn(props.translate("BOKA FYSISK BESÖK","PERSONAL VISIT"), `/${props.language}/bookLocal/`)}
            {menuBtn(props.translate("BOKA VIDEOSAMTAL","BOOK A VIDEOCALL"), `${props.language}/bookOnline/`)}
            {/*menuBtn(props.translate("ONLINE RÅDGIVNING","ONLINE KONSULTING"), `/${props.language}/onlineConsulting/`)*/}
            {menuBtn(props.translate("OM OSS","ABOUT US"), `/${props.language}/aboutUs`)}
            {menuBtn("", `/${languageTask(props.language)}/${props.currentPage}`,"iconTrue")}
            
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
