import React from 'react';
import './TypeField.css'; 

function TypeField(props) {
    return (
        <>
        <div className="inputHeadTitle">{props.title}</div>
        {props.textArea ? (<textarea className="textArea" name={props.name} onChange={props.onChange}></textarea>):(<input className="typeField" onChange={props.onChange} name={props.name}></input>)}  
        </>
    )
}

export default TypeField
