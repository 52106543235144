import React from 'react'
import './MiniHeroSection.css'

function MiniHeroSection(props) {
    return (
        <div className="MiniHeader">
            {props.sImage ? <img className="miniHerosImage" src={props.sImage} alt={props.sImageAlt} />:
              <img className="fullHerosImage" src={props.bImage} alt={props.sImageAlt}/>}
        </div>
    )
}

export default MiniHeroSection
