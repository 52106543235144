import React from 'react'
import './Title.css'

function Title(props) {
    return (
        <>
       { props.superTitle ? (<div className="superTitle">{props.superTitle}</div>):
                             <div className="bigTitle" dangerouslySetInnerHTML={{__html: props.bigTitle}} />}
                             
        
        </>
    )
}

export default Title
