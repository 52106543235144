import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import personal1 from '../assets/images/personal1.jpg'
import personal2 from '../assets/images/personal2.jpg'
import personal3 from '../assets/images/personal3.jpg'


function Cards(props) {
  return (
    <div className='cards'>
      <h1>{props.translate("Våra medarbetare","Our coworkers")}</h1>

      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={personal1}
              text='Sophie Michelsson'
              label={"Distriktsköterska / District nurse"}
              path='/bookLocal'
            />
            <CardItem
              src={personal2}
              text='Björn'
              label={"Receptionsansvarig / Reception manager"}
              path='/bookLocal'
            />
            <CardItem
              src={personal3}
              text='Marita Lundgren'
              label={"Sjuksköterska / Nurse"}
              path='/bookLocal'
            />
          </ul>
        </div>
      </div>

    </div>
  );
}

export default Cards;
