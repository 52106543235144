import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer(props) {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>

          <div className='footer-link-items'>
            <h2>{props.translate("Kontakta oss","Contact us")}</h2>
            <Link to='/'>{props.translate("Kontakta oss under klinikens timmar: 08:00 - 17:00")}</Link>
            <Link to='/'>{props.translate("Telefon: 08 10 15 00","Phone: 08 10 15 00")}</Link>
            <Link to='/'>{props.translate("Telefon internationellt: 0046 (8) 10 15 00", "Phone international: 0046 (8) 10 15 00")}</Link>
            <Link to='/'>Östermalmsgatan 47, 114 26 Stockholm</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Endocons</h2>
            <Link to='/bookLocal'>{props.translate("Boka på mottagning","Book on site")}</Link>
            <Link to='/bookOnline'>{props.translate("Boka videosamtal","Book a Videocall")}</Link>
            <Link to='/onlineConsulting'>{props.translate("Online rådgivning","Online Advice")}</Link>
            <Link to='/aboutus'>{props.translate("Om oss","About us")}</Link>
          </div>
          
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            {/* <Link to='/' className='social-logo'>
              Endocons
              <i className='fab fa-typo3' />
            </Link> */}
          </div>
          {/* <small className='website-rights'>ENDOCONS © 2021</small> */}
        </div>
      </section>
    </div>
  );
}

export default Footer;
