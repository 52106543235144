import React, { useState, useEffect} from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route, useParams, Navigate } from 'react-router-dom';
import BookLocal from './pages/bookLocal';
import BookOnline from './pages/BookOnline';
import AboutUs from './pages/aboutus';
import Hormones from './pages/Hormones';
import OnlineConsulting from './pages/OnlineConsulting';
import ConsultConfirmation from './pages/ConsultConfirmation';
import PageNotFound from './pages/PageNotFound';
import Footer from './components/Footer';





function App() {
  

  document.title = "Endocons"

  const [language, setLanguage] = useState("sv")
  const [currentPage, setCurrentPage] = useState("NoPageName")

  function Translation(textSv, textEn) {
    
    return(language == "sv" ? <div dangerouslySetInnerHTML={{__html: textSv}} /> : <div dangerouslySetInnerHTML={{__html: textEn}} />)
  }

  function UserProfile(getElement) {
    // get the language and genreName from route params
    const { pageLanguage } = useParams();
    const { genreName } = useParams();
    const { pageName } = useParams();
 
   
      setCurrentPage(pageName)
      setLanguage(pageLanguage)
    
      
    // now we have access to the username that came from the URL
    switch (pageName) {
      case "bookLocal":
        return <BookLocal translate={Translation} currentPage={pageName} language={language}   changeCurrentPage={setCurrentPage} genre={genreName} />; 

      case "bookOnline":
        return <BookOnline  translate={Translation} currentPage={pageName} language={language}   changeCurrentPage={setCurrentPage} genre={genreName} />; 

      case "onlineConsulting":
        return <OnlineConsulting  translate={Translation} currentPage={pageName} language={language}  changeCurrentPage={setCurrentPage} genre={genreName} />; 

      case "aboutUs":
        return <AboutUs translate={Translation} currentPage={pageName} language={language}   changeCurrentPage={setCurrentPage} genre={genreName} />; 
      
      case "hormones":
        return <Hormones  translate={Translation} currentPage={pageName} language={language}   changeCurrentPage={setCurrentPage} genre={genreName} />; 
      
      default:
        return <getElement.element  translate={Translation} currentPage={pageName} language={language}   changeCurrentPage={setCurrentPage} genre={genreName} />; 
    }
  }

  return (
    <>
      <Router>
        <Navbar translate={Translation} language={language} changeLanguage={setLanguage} currentPage={currentPage} changeCurrentPage={setCurrentPage}/>
        <Routes>
        <Route path="/" element={<Navigate to="/sv/"/>} />
          {/* <Route path='/' exact element={<UserProfile element={Home}  />} /> */}
          <Route path='//:pageLanguage' exact element={<UserProfile element={Home}  />} />
          <Route path='/:pageLanguage/:pageName' element={<UserProfile element={PageNotFound} />} />
          <Route path='/:pageLanguage/:pageName/:genreName' element={<UserProfile element={PageNotFound}/>} />
        </Routes>
      <Footer translate={Translation}/> 
      </Router>
    </>
  );
}

export default App;
