import React,{useState} from 'react'
import './ButtonNew.css'
import { Link } from 'react-router-dom';


function ButtonNew(props) {
    
    const verification = () => {
    if (props.title == "SKICKAT") {
        return("green")
    }
    return props.theme
}
    return (
        <Link to={props.goTo} className="link" onClick={()=>props.onClick()}>
        <div className= {"btnWindow "+ verification()}>
            <text>{props.title}</text>
           {props.awIcon ? <div className={props.awIcon + " icon"}></div> : null } 
        </div>
        </Link>
    )
}

export default ButtonNew