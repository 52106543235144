import React from 'react';
import '../App.css';


function BookOnline(props) {
  window.scrollTo(0, 0) //When pages loads is will scroll up
  console.log(props.data);
  return (
    <>
      
      <div style={{
        color: 'white',
        backgroundColor: 'rgb(38 38 38)',
        padding: "30px",
        
        }}>
          {props.translate(`<h1>VIDEOSAMTAL</h1>
          <br/>
          Boka själv din tid hos specialistläkare i endokrinologi såhär:
          <br/>
          <br/>
          1) Scrolla ned till önskad besöksdag i kalendern nedanför.
          <br/>
          2) Klicka därefter på ledigt tidspunkt under valt besöksdag.
          <br/>
          3) Fyll i informationsfälten nedanför.
          <br/>
          4) Klicka ”boka”.
          <br/>
          Bekräftelse av din bokning kommer omedelbart på din e-mail.
          Vid tveksamhet, ring istället receptionen som då utför bokningen åt dig: 08 10 15 00 under öppettid.`
          ,
          `<h1>VIDEOCALL</h1>
          <br/>
          Book your appointment with a specialist in endocrinology as follows:
          <br/>
          <br/>
          1) Scroll down to the desired visiting day in the calendar below.
          <br/>
          2) Then click on free time during the selected day of visit.
          <br/>
          3) Fill in the information fields below.
          <br/>
          4) Click ”boka”.
          <br/>
          Confirmation of your booking will be sent immediately to your e-mail.
          If in doubt, call the reception who will then make the booking for you: 08 10 15 00 during opening hours.`
                
          )
          
        }
        </div>
   
      <object width="100%" height="800" type="text/html" data="https://patient.nu/portal/public/calendar/cb7e8e08-fefd-11ea-bde6-67804bf5ecc1">Bokning</object>

    </>
  );
}

export default BookOnline;
